import { createStore } from "vuex";
import api from "../components/http/api";
import msg from "../components/http/msgbox";
export default createStore({
  state: {
    isLoading: false,
    pop: {
      isLoading: false,
      data: {
        action: "",
        msg: "",
        class: "",
        type: "",
      },
    },
  },
  mutations: {
    Loading(state, payload) {
      state.isLoading = payload;
    },
    Pop(state, payload) {
      state.pop = payload;
    },
  },
  actions: {
    getpost(context, parameter) {
      return new Promise((resolve) => {
        api.apiPost(parameter.action, parameter.body).then((res) => {
          if (msg.resPonseStatus(res)) {
            if (res.status == "fail") {
              if (
                parameter.action == "extra_bonus" ||
                parameter.action == "Register"
              )
                resolve(res);
              msg.API_popmsg(res.msg, "", "error");
            } else if (res.status == "relogin") {
              msg.API_popmsg(res.msg, "relogin", "error");
            } else {
              resolve(res);
            }
          }
        });
      });
    },
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
    pop: (state) => {
      return state.pop;
    },
  },
});

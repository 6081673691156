<template>
  <div
    class="position-fixed start-0 top-0 my-bg-white w-100 border-sha"
    style="z-index: 5"
  >
    <div class="container">
      <div class="d-flex align-items-center">
        <img
          src="@/assets/logo.png"
          width="150"
          class="me-lg-5 me-2"
          @click="this.$router.push('/')"
        />
        <div class="ms-auto">
          <!-- <ul
            class="d-xl-none d-flex align-items-center"
            style="margin-right: -10px"
          >
            <li
              @click="click_href('reg')"
              class="my-bg-black my-text-white rounded-5 py-1 px-2 fw-bold bg-hover-grey c-pointer me-2"
            >
              <a class="my-text-white" style="text-decoration: none">{{
                $t("text_3")
              }}</a>
            </li>
          </ul> -->

          <ul class="d-flex align-items-center">
            <li
              class="bg-yellow text-white rounded-5 py-2 px-4 fw-bold c-pointer"
              @click="click_href('reg')"
            >
              <a style="font-size: 22px">{{ $t("text_3") }}</a>
            </li>
          </ul>
        </div>
        <Lang />
      </div>
    </div>
  </div>
</template>

<script>
import Lang from "./Home/language.vue";
export default {
  name: "maintop",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: {
    Lang,
  },
  computed: {},
  methods: {
    click_href(id) {
      if (id == "reg") this.$router.push("/Reg");
      else this.$router.push("/");
    },
  },
  watch: {},
};
</script>

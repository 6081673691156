<template>
  <div class="container bg-blue radius-25 py-5 radius-1023-0 mt-5">
    <div class="row w-1023-100 w-1024-50 mx-auto">
      <h2 class="text-center pt-2">
        <span>預約報名</span>
        <span>Registration</span>
      </h2>
      <form method="POST" @submit="Register">
        <div class="form-floating">
          <input
            type="text"
            :placeholder="$t('text_5')"
            v-model="uid"
            required="required"
            class="p-25 form-control"
          />
          <label for="uid" class="text-secondary">{{ $t("text_5") }}</label>
        </div>
        <div class="form-floating mt-3">
          <input
            type="text"
            :placeholder="$t('text_6')"
            v-model="mobile"
            required="required"
            class="p-25 form-control"
          />
          <label for="mobile" class="text-secondary">{{ $t("text_6") }}</label>
        </div>
        <div class="form-floating mt-3">
          <input
            type="text"
            :placeholder="$t('text_10')"
            v-model="email"
            class="p-25 form-control"
          />
          <label for="email" class="text-secondary">{{ $t("text_10") }}</label>
        </div>
        <div class="form-floating mt-3">
          <input
            type="text"
            :placeholder="$t('text_7')"
            v-model="years"
            required="required"
            class="p-25 form-control"
          />
          <label for="years" class="text-secondary">{{ $t("text_7") }}</label>
        </div>
        <div class="mt-3">
          <textarea
            :placeholder="$t('text_9')"
            v-model="qcon"
            class="py-25 form-control"
            required="required"
          ></textarea>
        </div>
        <div class="col-6 mx-auto mt-3">
          <input
            type="submit"
            class="border-0 rounded-2 fw-bolder bg-yellow text-white py-2 px-4 fw-bold w-100"
            :value="$t('text_8')"
          />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
// import captchaview from "./Home/captcha.vue";

export default {
  name: "register",
  data() {
    return {
      uid: "",
      mobile: "",
      email: "",
      years: "",
      qcon: "",
    };
  },
  methods: {
    checkcode: function () {
      return this.$refs.captgetcode.getdata() == this.captcha ? true : false;
    },
    Register: function (e) {
      e.preventDefault();
      let param = {
        action: "Register",
        body: {
          cname: this.uid,
          tel: this.mobile,
          email: this.email,
          age: this.years,
          content: this.qcon,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.$MSG.API_popmsg(
            this.$t("Member_registerSuccessTitle"),
            "",
            "success"
          );
        } else {
          this.$MSG.API_popmsg(
            this.$t("Member_registerErrorTitle"),
            "",
            "warning"
          );
        }
      });
    },
  },
  watch: {},
  computed: {},
  // components: { captchaview },
};
</script>

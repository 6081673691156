<template>
  <div @click="this.$store.commit('Lang', false)" v-if="islang" class="ms-2">
    <div
      class="language_card d-flex align-items-center"
      id="dropdown_lang"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        src="@/assets/img/main/worldwide.png"
        style="width: 25px; height: 25px"
      />
      <!-- <div class="px-1">{{ $t("selectLang") }}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
        ></path>
      </svg> -->
    </div>

    <ul
      class="dropdown-lang dropdown-menu mt-3 border_eee"
      aria-labelledby="dropdown_lang"
    >
      <li @click="lang('en')">
        <a class="dropdown-item" href="#"
          ><img
            src="@/assets/img/lang_img/us.svg"
            class="me-1"
            style="width: 1.66em"
          />English</a
        >
      </li>
      <li @click="lang('cn')">
        <a class="dropdown-item" href="#"
          ><img
            src="@/assets/img/lang_img/cn.svg"
            class="me-1"
            style="width: 1.66em"
          />简体中文</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      locale: localStorage.getItem("locale"),
      islang: false,
    };
  },
  computed: {},
  watch: {
    locale: function (e) {
      localStorage.setItem("locale", e);
      window.location.reload();
    },
  },
  methods: {
    lang: function (e) {
      this.locale = e;
    },
  },
};
</script>

<template>
  <div class="container p-2">
    <div class="col-12 d-flex justify-content-between">
      <div class="col-lg-6 col-12 mt-lg-5 mt-3">
        <h1 class="fw-bold fs-f-3rem lg-text-6xl">讓小孩們快樂學習</h1>
        <p class="mx-auto intro_p w-100 mb-auto">您的一念之間改變小孩一輩子</p>
        <p class="mx-auto intro_p w-100 mt-3">
          親愛的家長們：<br />在少子化的年代您是否也希望您的孩子們除了快樂學習外也能夠激發他的潛能呢？<br />卻常不知如何提升您家寶貝的能力呢？<br />
          請盡速撥打我們的專線，讓我們一起來幫您達成想要的成果，我們有免費試上課程。<br />歡迎來電預約!!<br />
          <a href="tel:0986372525">連絡電話：0986372525</a>
        </p>
        <p class="lg-fs-f-normal fs-f-1-125rem mt-lg-0 mt-3 text-center">
          {{ $t("text_2") }}
        </p>

        <div class="d-block">
          <button
            class="bg-yellow text-white rounded-5 px-4 fw-bold fs-f-20 border-0 ls-001 text-nowrap w-100"
            name="button"
            style="padding-top: 16px; padding-bottom: 16px"
            type="button"
            @click="click_href('reg')"
          >
            {{ $t("text_3") }}
          </button>
        </div>
      </div>
      <div class="col-5 d-lg-block d-none my-auto sun">
        <div class="sunny"></div>
        <img
          src="@/assets/img/main/1.jpg"
          class="w-100 radius-100 border-yellow-sun"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hometop",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: {},
  computed: {},
  methods: {
    click_href(id) {
      if (id == "reg") this.$router.push("/Reg");
      else this.$router.push("/");
    },
  },
  watch: {},
};
</script>

<template>
  <div class="container pt-5 pb-2">
    <div class="row w-100 mx-auto">
      <div class="col-4 col-1023-12 d-flex flex-column" @click="click()">
        <img
          src="@/assets/img/main/media_20240408_224931_2677889545233991578.jpg"
          class="w-100 w-768-75 c-pointer"
        />
        <p class="mt-4 f-18 c-pointer text-center" style="padding-left: 10px">
          FB：新竹湳雅蒙特梭利親子館
        </p>
      </div>
      <div class="col-4 col-1023-12">
        <a href="tel:0986372525" class="c-pointer d-flex flex-column">
          <img
            src="@/assets/img/main/media_20240408_224931_5506463999809934640.jpg"
            class="w-100 w-768-75"
          />
          <p class="mt-4 f-18 text-center" style="padding-left: 10px">
            連絡電話：0986372525
          </p>
        </a>
      </div>
      <div class="col-4 col-1023-12">
        <a href="https://maps.app.goo.gl/VgDQ1VE5d89v6Lvn9" target="_blank" class="c-pointer d-flex flex-column">
          <img
            src="@/assets/img/main/media_20240408_224931_5455912230220594175.jpg"
            style="cursor: default"
            class="w-100 w-768-75"
          />
          <p
            class="mt-4 f-18 text-center"
            style="padding-left: 10px; cursor: default"
          >
            地址：新竹市東大路二段173巷2弄8號<br />【原新竹湳雅蒙特梭利】
          </p>
        </a>
      </div>
    </div>
    <div class="row w-100 mx-auto mt-5">
      <div class="col-12">
        <p class="text-center">蒙特梭利 © 2018 All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: {},
  computed: {},
  methods: {
    click() {
      window.open(
        "https://www.facebook.com/people/%E6%96%B0%E7%AB%B9%E6%B9%B3%E9%9B%85%E8%92%99%E7%89%B9%E6%A2%AD%E5%88%A9%E8%A6%AA%E5%AD%90%E9%A4%A8/100063831879178/"
      );
    },
  },
  watch: {},
};
</script>
